.slate-toolbar.slate-toolbar-floating {
  @apply border-indigo-400 border-2 shadow-lg rounded-md;
}

.slate-menu {
  & > * {
    display: inline-block;
  }

  & > * + * {
    margin-left: 2px;
  }
}
